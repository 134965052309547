'use strict';

(function() {
  function CartResource($resource) {
    return $resource('api/v1/cart/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      getByOrganization: {
        method: 'GET',
        params: {
          id: 'byorg'
        }
      },
      addFinding: {
        method: 'POST',
        params: {
          id: 'byorg'
        }
      },
      removeFinding: {
        method: 'DELETE',
        params: {
          id: 'byorg'
        }
      }

    });
  }

  angular.module('windmanagerApp')
    .factory('Cart', CartResource);
})();
